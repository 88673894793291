<template>
<div class="grid dashboard-banking">
    <div class="col-12 md:col-4 lg:col-2 overview-boxes">
        <div class="overview clearfix">
            <div class="overview-icon">
                <img src="layout/images/dashboard-banking/icon-transfer.png" alt="avalon-layout" width="52" class="icon-transfer" />
            </div>

            <div class="overview-text">
                <span class="overview-title">Make</span>
                <span class="overview-subtitle">Swift Transfer</span>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-4 lg:col-2 overview-boxes">
        <div class="overview clearfix">
            <div class="overview-icon">
                <img src="layout/images/dashboard-banking/icon-creditcards.png" alt="avalon-layout" width="52" class="icon-creditcards" />
            </div>

            <div class="overview-text">
                <span class="overview-title">Pay</span>
                <span class="overview-subtitle">Credit Cards</span>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-4 lg:col-2 overview-boxes">
        <div class="overview clearfix">
            <div class="overview-icon">
                <img src="layout/images/dashboard-banking/icon-cardtransfer.png" alt="avalon-layout" width="52" class="icon-cardtransfer" />
            </div>

            <div class="overview-text">
                <span class="overview-title">Make</span>
                <span class="overview-subtitle">Card Transfer</span>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-4 lg:col-2 overview-boxes">
        <div class="overview clearfix">
            <div class="overview-icon">
                <img src="layout/images/dashboard-banking/icon-receivemoney.png" alt="avalon-layout" width="52" class="icon-receivemoney" />
            </div>

            <div class="overview-text">
                <span class="overview-title">Receive</span>
                <span class="overview-subtitle">Money</span>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-4 lg:col-2 overview-boxes">
        <div class="overview clearfix">
            <div class="overview-icon">
                <img src="layout/images/dashboard-banking/icon-transactions.png" alt="avalon-layout" width="52" class="icon-transactions" />
            </div>

            <div class="overview-text">
                <span class="overview-title">See</span>
                <span class="overview-subtitle">Transactions</span>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-4 lg:col-2 overview-boxes">
            <div class="overview clearfix">
                <div class="overview-icon">
                    <img src="layout/images/dashboard-banking/icon-ticket.png" alt="avalon-layout" width="52" class="icon-ticket" />
                </div>

                <div class="overview-text">
                    <span class="overview-title">Open</span>
                    <span class="overview-subtitle">Ticket</span>
                </div>
            </div>
        </div>

    <div class="col-12 md:col-6 lg:col-3 currency-panel clearfix">
        <div class="dashboard-currency">
            <div class="dashboard-currency-icon">
                <img src="layout/images/dashboard-banking/icon-dollar.svg" alt="avalon-layout" width="30" class="icon-dollar" />
            </div>

            <div class="dashboard-currency-label">
                <div class="dashboard-currency-title">USD</div>
                <div class="dashboard-currency-subtitle">US Dollar</div>
            </div>

            <div class="dashboard-currency-rates">
                <div class="dashboard-currency-rate">
                    <span class="currency-rate-title">Latest</span>
                    <span class="currency-rate-value">3,5232</span>
                </div>

                <div class="dashboard-currency-rate">
                    <span class="currency-rate-title">Change</span>
                    <span class="currency-rate-value">-0.85</span>
                </div>
            </div>
        </div>
    </div>

    <div class=" col-12 md:col-6 lg:col-3 currency-panel clearfix">
        <div class="dashboard-currency">
            <div class="dashboard-currency-icon">
                <img src="layout/images/dashboard-banking/icon-euro.svg" alt="avalon-layout" width="30" class="icon-euro" />
            </div>

            <div class="dashboard-currency-label">
                <div class="dashboard-currency-title">EUR</div>
                <div class="dashboard-currency-subtitle">Euro</div>
            </div>

            <div class="dashboard-currency-rates">
                <div class="dashboard-currency-rate">
                    <span class="currency-rate-title">Latest</span>
                    <span class="currency-rate-value">4,1246</span>
                </div>

                <div class="dashboard-currency-rate">
                    <span class="currency-rate-title">Change</span>
                    <span class="currency-rate-value">-0.16</span>
                </div>
            </div>
        </div>
    </div>

    <div class=" col-12 md:col-6 lg:col-3 currency-panel clearfix">
        <div class="dashboard-currency">
            <div class="dashboard-currency-icon">
                <img src="layout/images/dashboard-banking/icon-gbp.svg" alt="avalon-layout" width="30" class="icon-gbp" />
            </div>

            <div class="dashboard-currency-label">
                <div class="dashboard-currency-title">GBP</div>
                <div class="dashboard-currency-subtitle">Pound</div>
            </div>

            <div class="dashboard-currency-rates">
                <div class="dashboard-currency-rate">
                    <span class="currency-rate-title">Latest</span>
                    <span class="currency-rate-value">4,6300</span>
                </div>

                <div class="dashboard-currency-rate">
                    <span class="currency-rate-title">Change</span>
                    <span class="currency-rate-value">-0.25</span>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-6 lg:col-3 currency-panel clearfix">
            <div class="dashboard-currency">
                <div class="dashboard-currency-icon">
                    <img src="layout/images/dashboard-banking/icon-gold.svg" alt="avalon-layout" width="30" class="icon-gold" />
                </div>

                <div class="dashboard-currency-label">
                    <div class="dashboard-currency-title currency-gold">GOLD</div>
                </div>

                <div class="dashboard-currency-rates">
                    <div class="dashboard-currency-rate">
                        <span class="currency-rate-title">Latest</span>
                        <span class="currency-rate-value">143,059</span>
                    </div>

                    <div class="dashboard-currency-rate">
                        <span class="currency-rate-title">Change</span>
                        <span class="currency-rate-value">-0.85</span>
                    </div>
                </div>
            </div>
        </div>

    <div class="col-12 md:col-12 lg:col-4 financial-overview">
        <div class="card">
            <h5>Accounts</h5>
            <div class="financial-overview-total">
                <span class="financial-overview-total-label">Grand Total</span>
                <span class="financial-overview-total-value">624,504.00</span>

                <div class="clearfix"></div>
            </div>

            <div class="panel-inner">
                <div class="financial-overview-line">
                    <div class="line-left">
                                <span class="line-icon">
                                    <i class="pi pi-money-bill"></i>
                                </span>
                        <span class="line-label">Bank Accounts</span>
                    </div>

                    <div class="line-right">
                        <span class="line-value">$320,521.25</span>
                        <a href="#" class="line-caret"><i class="pi pi-angle-double-right"></i></a>
                    </div>

                    <div class="clearfix"></div>
                </div>

                <div class="financial-overview-line">
                    <div class="line-left pull-left">
                        <span class="line-icon">
                            <i class="pi pi-briefcase"></i>
                        </span>
                        <span class="line-label">Savings</span>
                    </div>

                    <div class="line-right pull-right ">
                        <span class="line-value">$94,305.00</span>
                        <a href="#" class="line-caret"><i class="pi pi-angle-double-right"></i></a>
                    </div>

                    <div class="clearfix"></div>
                </div>

                <div class="financial-overview-line">
                    <div class="line-left pull-left">
                        <span class="line-icon">
                            <i class="pi pi-globe"></i>
                        </span>
                        <span class="line-label">Stocks</span>
                    </div>

                    <div class="line-right pull-right ">
                        <span class="line-value">$5,000.00</span>
                        <a href="#" class="line-caret"><i class="pi pi-angle-double-right"></i></a>
                    </div>

                    <div class="clearfix"></div>
                </div>

                <div class="financial-overview-line">
                    <div class="line-left pull-left">
                        <span class="line-icon">
                            <i class="pi pi-dollar"></i>
                        </span>
                        <span class="line-label">Profit</span>
                    </div>

                    <div class="line-right pull-right ">
                        <span class="line-value">$21.25</span>
                        <a href="#" class="line-caret"><i class="pi pi-angle-double-right"></i></a>
                    </div>

                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-12 lg:col-4 financial-overview">
        <div class="card">
            <h5>Debts</h5>
            <div class="financial-overview-total">
                <span class="financial-overview-total-label">Grand Total</span>
                <span class="financial-overview-total-value">125,330.00</span>

                <div class="clearfix"></div>
            </div>

            <div class="panel-inner">
                <div class="financial-overview-line">
                    <div class="line-left pull-left">
                        <span class="line-icon">
                            <i class="pi pi-briefcase"></i>
                        </span>
                        <span class="line-label">Used Credits</span>
                    </div>

                    <div class="line-right pull-right">
                        <span class="line-value">$100,240.00</span>
                        <a href="#" class="line-caret"><i class="pi pi-angle-double-right"></i></a>
                    </div>

                    <div class="clearfix"></div>
                </div>

                <div class="financial-overview-line">
                    <div class="line-left pull-left">
                        <span class="line-icon">
                            <i class="pi pi-calendar"></i>
                        </span>
                        <span class="line-label">Loans</span>
                    </div>

                    <div class="line-right pull-right ">
                        <span class="line-value">$25,090.00</span>
                        <a href="#" class="line-caret"><i class="pi pi-angle-double-right"></i></a>
                    </div>

                    <div class="clearfix"></div>
                </div>

                <div class="financial-overview-line">
                    <div class="line-left pull-left">
                        <span class="line-icon">
                            <i class="pi pi-ticket"></i>
                        </span>
                        <span class="line-label">Overdrafts</span>
                    </div>

                    <div class="line-right pull-right ">
                        <span class="line-value">$0</span>
                        <a href="#" class="line-caret"><i class="pi pi-angle-double-right"></i></a>
                    </div>

                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 md:col-12 lg:col-4 financial-overview">
            <div class="card">
                <a href="#" class="thumbnail nopadding">
                    <img src="layout/images/dashboard-banking/asset-japan.png" alt="avalon-layout" width="30" class="asset-japan " />
                </a>
                <div class="panel-inner no-padding-top">
                    <h3 class="mt-3"><strong>Journey to Japan</strong></h3>
                    <div class="progress">
                        <ProgressBar :value="30" styleClass="pro" style='height: .5rem' :showValue='false'></ProgressBar>
                    </div>

                    <div class="progress-explaination my-3">
                        <p><span><b>$3,000.00</b></span> from $10,000.00</p>
                    </div>

                    <div class="progress-buttons my-3">
                        <Button label="Deposit"></Button>
                        <a href="#" class="pull-right button-link">Withdraw</a>
                    </div>
                </div>

                <div class="clearfix"></div>
            </div>
        </div>

    <div class="col-12 md:col-12 lg:col-4">
        <div class="card expenses">
            <h5>Expenses</h5>
            <div class='flex flex-wrap justify-content-evenly'>
                <img src='layout/images/dashboard-banking/chart-expenses.svg' alt='avalon-layout' class='chart-expenses' height='200' />

                <div class='chart-details'>
                    <div class='chart-label'>
                        <i class='pi pi-circle-on shape1'></i>Segment 1
                    </div>
                    <div class='chart-label-name'>Advertisement</div>
                    <div class='chart-status status1'>
                        <i class='pi pi-angle-up'></i>5% increased
                    </div>

                    <div class='chart-label'>
                        <i class='pi pi-circle-on shape2'></i>Segment 2
                    </div>
                    <div class='chart-label-name'>Bills</div>
                    <div class='chart-status status1'>
                        <i class='pi pi-angle-up'></i>12% increased
                    </div>

                    <div class='chart-label'>
                        <i class='pi pi-circle-on shape3'></i>Segment 3
                    </div>
                    <div class='chart-label-name'>Salaries</div>
                    <div class='chart-status status2'>
                        <i class='pi pi-angle-down'></i>3% decreased
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class=" col-12 md:col-12 lg:col-8">
            <div class="card widget-messages">
                <h5>Messages</h5>

                <div class="flex-row">
                    <div class="message-box">
                        <div class="message">
                            <img src="layout/images/dashboard-banking/profile.png" alt="avalon-layout" class="messager-img" />

                            <div class="messager">Joshua Williams
                                <i class="pi pi-circle-on online"></i>
                                <span class="date pull-right">Today</span>
                            </div>

                            <div class="message-text">Paenitet me quod tu me rogas? Oh, sic, qui stultus plastic continentis rogavi te ut emas. Vides non manducare acidum hydrofluoric per plastic. </div>
                        </div>

                        <div class="clearfix"></div>
                    </div>

                    <div class="message-box">
                        <div class="message">
                            <img src="layout/images/dashboard-banking/profile1.png" alt="avalon-layout" class="messager-img" />

                            <div class="messager">Adelle Charles
                                <i class="pi pi-circle-on offline"></i>
                                <span class="date pull-right">Today</span>
                            </div>

                            <div class="message-text">Quinquaginta septem est - pars tua, triginta quinque millia. Est autem extra plus quindecim, tota tua est, quom meruisset.</div>
                        </div>

                        <div class="clearfix"></div>
                    </div>

                    <div class="message-box">
                        <div class="message">
                            <img src="layout/images/dashboard-banking/profile2.png" alt="avalon-layout" class="messager-img" />

                            <div class="messager">Marcos Moralez
                                <i class="pi pi-circle-on online"></i>
                                <span class="date pull-right">Yesterday</span>
                            </div>

                            <div class="message-text">Fac nos fecit. SIC.</div>
                        </div>

                        <div class="clearfix"></div>
                    </div>

                    <div class="message-box">
                        <div class="message">
                            <img src="layout/images/dashboard-banking/profile3.png" alt="avalon-layout" class="messager-img" />

                            <div class="messager">Matt Litherland
                                <i class="pi pi-circle-on online"></i>
                                <span class="date pull-right">3 days ago</span>
                            </div>

                            <div class="message-text">Puto quia una res potest - venimus in cognitionem. Vide pretium in manibus.</div>
                        </div>

                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>

    <div class="col-12 md:col-12 lg:col-6">
        <div class="card">
            <h5>Monthly Statistics</h5>

            <Chart type="bar" :data="visitorChart" :options="visitorChartOptions"/>
        </div>
    </div>

    <div class="col-12 md:col-12 lg:col-6">
            <div class="card">
                <h5>Transactions</h5>

                <table style="width:100%">
                    <tr class="transaction">
                        <td>
                            <img src="layout/images/dashboard-banking/asset-visa.png" alt="avalon-layout" width="52" class="asset-visa" />
                        </td>

                        <td class="transaction-date-cont transaction-item ">
                            <span class="transaction-title">07 July 2017</span>
                            <span class="transaction-subtitle">16.21</span>
                        </td>

                        <td class="transaction-name transaction-item ">
                            <span class="transaction-title"><strong>Recharge Card</strong></span>
                            <span class="transaction-subtitle">Card *8076</span>
                        </td>

                        <td class="transaction-button transaction-item ">
                            <Button label="See Details"></Button>
                        </td>
                    </tr>
                    <tr class="transaction">
                        <td class="transaction-img ">
                            <img src="layout/images/dashboard-banking/asset-netflix.png" alt="avalon-layout" width="52"/>
                        </td>

                        <td class="transaction-date-cont transaction-item">
                            <span class="transaction-title">06 July 2017</span>
                            <span class="transaction-subtitle">11.05</span>
                        </td>

                        <td class="transaction-name transaction-item ">
                            <span class="transaction-title"><strong>Online Payment</strong></span>
                            <span class="transaction-subtitle">Netflix</span>
                        </td>

                        <td class="transaction-button transaction-item ">
                            <Button label="See Details"></Button>
                        </td>
                    </tr>
                    <tr class="transaction">
                        <td class="transaction-img ">
                            <img src="layout/images/dashboard-banking/asset-spotify.png" alt="avalon-layout" width="52" />
                        </td>

                        <td class="transaction-date-cont transaction-item ">
                            <span class="transaction-title">05 July 2017</span>
                            <span class="transaction-subtitle">08.59</span>
                        </td>

                        <td class="transaction-name transaction-item ">
                            <span class="transaction-title"><strong>Online Payment</strong></span>
                            <span class="transaction-subtitle">Spotify</span>
                        </td>

                        <td class="transaction-button transaction-item ">
                            <Button label="See Details"></Button>
                        </td>
                    </tr>
                    <tr class="transaction">
                        <td class="transaction-img ">
                            <img src="layout/images/dashboard-banking/asset-newyorker.png" alt="avalon-layout" width="52" />
                        </td>

                        <td class="transaction-date-cont transaction-item ">
                            <span class="transaction-title">05 July 2017</span>
                            <span class="transaction-subtitle">14.49</span>
                        </td>

                        <td class="transaction-name transaction-item ">
                            <span class="transaction-title"><strong>Purchase</strong></span>
                            <span class="transaction-subtitle">The New Yorker</span>
                        </td>

                        <td class="transaction-button transaction-item ">
                            <Button label="See Details"></Button>
                        </td>
                    </tr>

                    <tr class="transaction">
                        <td class="transaction-img ">
                            <img src="layout/images/dashboard-banking/asset-google.png" width="52" alt="avalon-layout"/>
                        </td>

                        <td class="transaction-date-cont transaction-item ">
                            <span class="transaction-title">02 July 2023</span>
                            <span class="transaction-subtitle">12.51</span>
                        </td>

                        <td class="transaction-name transaction-item ">
                            <span class="transaction-title"><strong>Online Payment</strong></span>
                            <span class="transaction-subtitle">Google Workspace</span>
                        </td>

                        <td class="transaction-button transaction-item ">
                            <Button label="See Details"></Button>
                        </td>
                    </tr>

                    <tr class="transaction">
                        <td class="transaction-img ">
                            <img src="layout/images/dashboard-banking/asset-soundcloud.png" width="52" alt="avalon-layout"/>
                        </td>

                        <td class="transaction-date-cont transaction-item ">
                            <span class="transaction-title">01 July 2023</span>
                            <span class="transaction-subtitle">09.27</span>
                        </td>

                        <td class="transaction-name transaction-item ">
                            <span class="transaction-title"><strong>Online Payment</strong></span>
                            <span class="transaction-subtitle">SoundCloud</span>
                        </td>

                        <td class="transaction-button transaction-item ">
                            <Button label="See Details"></Button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

    <div class="col-12 md:col-12 lg:col-8 ">
        <div class="card assets">
            <h5>Products</h5>
            <DataTable :value="products" :rows="5" style="margin-bottom: 20px" :paginator="true" responsiveLayout="scroll">
                <Column>
                    <template #header>
                        Logo
                    </template>
                    <template #body="slotProps">
                        <img :src="'demo/images/product/' + slotProps.data.image" :alt="slotProps.data.image" width="50" />
                    </template>
                </Column>
                <Column field="name" header="Name" :sortable="true">
                    <template #body="slotProps">
                        <span class="p-column-title">Name</span>
                        {{slotProps.data.name}}
                    </template>
                </Column>
                <Column field="category" header="Category" :sortable="true">
                    <template #body="slotProps">
                        <span class="p-column-title">Category</span>
                        {{slotProps.data.category}}
                    </template>
                </Column>
                <Column field="price" header="Price" :sortable="true">
                    <template #body="slotProps">
                        <span class="p-column-title">Price</span>
                        {{formatCurrency(slotProps.data.price)}}
                    </template>
                </Column>
                <Column>
                    <template #header>
                        View
                    </template>
                    <template #body>
                        <Button icon="pi pi-search" type="button" class="p-button-success p-button-rounded p-button-outlined mr-2 mb-1"></Button>
                        <Button icon="pi pi-times" type="button" class="p-button-danger p-button-rounded p-button-outlined mb-1"></Button>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>

    <div class="col-12 md:col-12 lg:col-4 no-margin-top">
            <div class="card customer-support">
                <h5>Customer Support</h5>
                <div class="support-top">
                    <img src="layout/images/dashboard-banking/icon-customersupport.png" alt="avalon-layout" class="pull-left" />

                    <div class="support-estimation">
                        <span class="est-title"><strong>Estimated Wait Time</strong></span>
                        <span class="est-time">21 minutes</span>
                    </div>

                    <div class="clearfix"></div>
                </div>

                <div class="p-fluid">
                    <div class="col-12">
                        <Dropdown :options="dropdownItem" v-model="selectedDropdownItem" optionLabel="name" placeholder="Select One"></Dropdown>
                    </div>
                    <div class="col-12">
                        <InputText type="text" placeholder="Name" />
                    </div>
                    <div class="col-12">
                        <InputText type="text" placeholder="Subject" />
                    </div>
                    <div class="col-12">
                        <Textarea placeholder="Message" :autoResize="false" :rows="2"></Textarea>
                    </div>
                    <div class="col-12 btn-padding">
                        <Button type="button" pButton label="Send" icon="pi pi-check"></Button>
                    </div>
                </div>
            </div>
        </div>

</div>
</template>

<script>
import ProductService from '../service/ProductService';

export default {
    data() {
        return {
            visitorChart: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
                datasets: [
                    {
                        data: [600, 671, 660, 665, 700, 610, 810, 790, 710, 860, 810, 780],
                        backgroundColor: '#6f42c1',
                        fill: true,
                        barPercentage: 0.75,
                        stepped: true
                    }
                ]
            },
            visitorChartOptions: {
                plugins: {
                    legend: {
                        display: false
                    }
                },
                responsive: true,
                hover: {
                    mode: 'index'
                },
                scales: {
                    y: {
                        min: 500,
                        max: 900,
                        ticks: {
                            color: '#A0A7B5'
                        },
                        grid: {
                            color:  'rgba(160, 167, 181, .3)',
                        }
                    },
                    x: {
                        ticks: {
                            color: '#A0A7B5'
                        },
                        grid: {
                            display: false
                        }
                    }
                }
            },
            cols:  [
                {field: 'vin', header: 'Vin'},
                {field: 'year', header: 'Year'},
                {field: 'brand', header: 'Brand'},
                {field: 'color', header: 'Color'}
            ],
            dropdownItem: [
                {name: 'Xbox Series X', code: 'XO'},
                {name: 'PlayStation 5', code: 'PS4'},
                {name: 'Nintendo Switch', code: 'WU'}
            ],
            dropdownCity: null,
			products: null,
            selectedProducts: null,
            selectedDropdownItem: null
        }
    },
    productService: null,
    created() {
        this.productService = new ProductService();
    },
    mounted() {
        this.productService.getProducts().then(data => this.products = data);
    },
    methods: {
        formatCurrency(value) {
			return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
		}
    }
}
</script>

<style lang="scss" scoped>
.card:last-child {
    margin-bottom: 0;
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}
			.p-datatable-tbody > tr {
				border-bottom: 1px solid #dee2e6;
				> td {
					text-align: left;
					display: flex;
					align-items: center;
					justify-content: center;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;
					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}
					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}
}
</style>
